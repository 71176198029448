import "./page-not-found.css";
import { useNavigate } from "react-router-dom";
import Button from "@mui/joy/Button";

export default function PageNotFound() {
  const navigate = useNavigate(); // Get the navigate function
  return (
    <div className="not-found-main">
      <div className="huge-text-404">404</div>
      <div className="big-text-404">Uh-oh! Nothing to see here</div>
      <br />
      <Button
        color="primary"
        variant="soft"
        size="lg"
        onClick={() => {
          navigate("");
        }}
      >
        Go back to Home
      </Button>
    </div>
  );
}
