import { API } from "../api-end-point";
import { usePostData } from "../api-service/usePostData";

export const useAddUser = ({ callBackOnSuccess }) =>
  usePostData({
    hideSuccessToast:true,
    endpoint: API.addUser,
    queryKeysToInvalidate: [["users"]],
    callBackOnSuccess,
  });
