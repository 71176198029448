import "./sign-up.css";
import Logo from "../../components/Logo/logo";
import { Button } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import Stepper from "@mui/joy/Stepper";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import React, { useState, useMemo } from "react";
import Select from "react-select";
import Option from "@mui/joy/Option";
import Input from "@mui/joy/Input";
import { Link } from "react-router-dom";
import TermsAndConditions from "../../components/terms-and-conditions/terms-and-conditions.js";
import countryList from "react-select-country-list";
import { useAddUser } from "../../api/users/use-add-user.js";
import MuiSelect from "@mui/joy/Select";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function bigOrEqual(num1, num2) {
  return num1 >= num2;
}
function equal(num1, num2) {
  return num1 === num2;
}
function SignUp(props) {
  const navigate = useNavigate(); // Get the navigate function
  const [count, setCount] = useState(1);
  const [passport, setPassport] = React.useState(null);
  const options = useMemo(() => countryList().getData(), []);
  const { mutate: addUser, isPending } = useAddUser({
    callBackOnSuccess: () => {
      toast.success("Request registered.");
      navigate("/sign-in");
    },
  });
  const [base64, setBase64] = useState("");
  const [IDbase64, setIDBase64] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        setBase64(base64String);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFileChange2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        setIDBase64(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  //   const [registrationAmtUSD, setRegistrationAmtUSD] = useState("");
  //   const [registrationAmtBTC, setRegistrationAmtBTC] = useState("");

  //   const handleUSDChange = (e) => {
  //     setRegistrationAmtUSD(e.target.value);
  //     if (e.target.value !== "") {
  //       setRegistrationAmtBTC("");
  //     }
  //   };

  //   const handleBTCChange = (e) => {
  //     setRegistrationAmtBTC(e.target.value);
  //     if (e.target.value !== "") {
  //       setRegistrationAmtUSD("");
  //     }
  //   };

  return (
    <div className="main-signin">
      <div className="floaty-middle">
        <div>
          {/* <Logo/> */}
          <div style={{ fontSize: "3rem", fontWeight: "bold" }}>Welcome!</div>
          <div style={{ fontSize: "2rem", fontWeight: "bold" }}>
            Create an account
          </div>
        </div>
        <div
          style={{
            margin: "auto",
            width: "fit-content",
            height: "auto",
            borderRadius: "8px",
            padding: "10px 10px",
          }}
        >
          <Stepper sx={{ width: "100%" }}>
            <Step
              orientation="vertical"
              indicator={
                <StepIndicator
                  variant={bigOrEqual(count, 1) ? "solid" : "plain"}
                  color="primary"
                >
                  1
                </StepIndicator>
              }
            >
              Personal Info
            </Step>
            <Step
              orientation="vertical"
              indicator={
                <StepIndicator
                  variant={bigOrEqual(count, 2) ? "solid" : "outlined"}
                  color={bigOrEqual(count, 2) ? "primary" : "neutral"}
                >
                  <span>2</span>
                </StepIndicator>
              }
            >
              Login Info
            </Step>
            <Step
              orientation="vertical"
              indicator={
                <StepIndicator
                  variant={bigOrEqual(count, 3) ? "solid" : "outlined"}
                  color={bigOrEqual(count, 3) ? "primary" : "neutral"}
                >
                  <span>3</span>
                </StepIndicator>
              }
            >
              Documents
            </Step>
          </Stepper>
          <div>
            <div className="sign-up-form">
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  const formData = new FormData(event.target);

                  formData.append("profile.passport_picture", base64);
                  formData.append("profile.passport_picture", IDbase64);
                  const requestData = {};
                  formData.forEach((value, key) => {
                    if (key.startsWith("profile.")) {
                      const profileKey = key.split(".")[1];
                      requestData.profile = {
                        ...requestData.profile,
                        [profileKey]: value,
                      };
                    } else {
                      requestData[key] = value;
                    }
                  });

                  addUser(requestData);
                }}
              >
                <div className={equal(count, 1) ? "" : "hidden"}>
                  <Stack spacing={1} direction="column">
                    First name
                    <Input placeholder="" variant="soft" name="first_name" />
                    Last name
                    <Input placeholder="" variant="soft" name="last_name" />
                    Gender
                    <MuiSelect defaultValue="MALE" name="profile.gender">
                      <Option value="MALE">Male</Option>
                      <Option value="FEMALE">Female</Option>
                      <Option value="OTHERS">Other</Option>
                    </MuiSelect>
                    E-mail
                    <Input
                      placeholder=""
                      variant="soft"
                      type="email"
                      name="email"
                    />
                    Country
                    <Select
                      options={options}
                      style={{ color: "black" }}
                      name="profile.country"
                    />
                    Mobile number
                    <Input
                      placeholder=""
                      variant="soft"
                      name="profile.phone_number"
                    />
                    Date of birth
                    <input
                      aria-label="Date"
                      type="date"
                      style={{ color: "black" }}
                      name="profile.date_of_birth"
                    />
                    {/* Participation amount
                        <Input
                            style={{width:"auto"}}
                            placeholder="Enter amount"
                            type="number"
                            endDecorator={
                            <React.Fragment>
                                <Divider orientation="vertical" />
                                <div style={{paddingLeft:"10px"}}>
                                BTC
                                </div>
                            </React.Fragment>
                            }
                            sx={{ width: 300 }}
                        /> */}
                  </Stack>
                  <br></br>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div></div>
                    <Button onClick={() => setCount(2)}>Next</Button>
                  </div>
                </div>
                <div className={equal(count, 2) ? "" : "hidden"}>
                  <Stack spacing={1} direction="column">
                    Username
                    <Input placeholder="" variant="soft" name="username" />
                    Password
                    <Input
                      placeholder=""
                      type="password"
                      variant="soft"
                      name="password"
                    />
                    Confirm password
                    <Input placeholder="" type="password" variant="soft" />
                  </Stack>

                  <br></br>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() => setCount(1)}
                    >
                      Back
                    </Button>
                    <Button onClick={() => setCount(3)}>Next</Button>
                  </div>
                </div>

                <div className={equal(count, 3) ? "" : "hidden"}>
                  <Stack spacing={1} direction="column">
                    {/* Registration amount USD
                    {(registrationAmtBTC!=="")?(<Alert
                        color="danger"
                        size="sm"
                        variant="outlined"
                        >Can input either BTC or USD amount</Alert>):""}
                        <Input 
                        placeholder=""
                        variant="soft"
                        name="registration_amt_usd"
                        value={registrationAmtUSD}
                        onChange={handleUSDChange}
                        disabled={registrationAmtBTC !== ""}/>
                    Registration amount BTC
                    {(registrationAmtUSD!=="")?(<Alert
                        color="danger"
                        size="sm"
                        variant="outlined"
                        >Can input either BTC or USD amount</Alert>):""}
                    <Input 
                        placeholder=""
                        variant="soft"
                        name="registration_amt_btc"
                        value={registrationAmtBTC}
                        onChange={handleBTCChange}
                        disabled={registrationAmtUSD !== ""}
                    
                    />
                    Transaction mode
                    
                    <MuiSelect defaultValue="BANK" name="trans_mode">
                        <Option value="BANK">Bank</Option>
                        <Option value="CRYPTO">Crypto</Option>
                    </MuiSelect> */}
                    Select ID copy
                    <input
                      type="file"
                      onChange={handleFileChange2}
                      name="id_picture"
                    />
                    Select Passport copy
                    <input
                      type="file"
                      onChange={handleFileChange}
                      name="passport_picture"
                    />
                  </Stack>
                  <br />
                  <TermsAndConditions text="submit" styleName="white-ref" />
                  <br />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() => setCount(2)}
                    >
                      Back
                    </Button>
                    <Button type="submit">Submit</Button>
                  </div>
                </div>
              </form>

              <br />
              <Link
                style={{ textDecoration: "none" }}
                to="/sign-in"
                className="formatted-link"
              >
                Or Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
