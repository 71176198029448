import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useListCustomTransactionMonthly = () => {
  return useReadData({
    queryKey: [["transactions_monthly 6"]],
    endpoint: API.custom_transactions_monthly,
  });
};

export { useListCustomTransactionMonthly };
