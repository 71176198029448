import "./forgot-password.css";
import Input from "@mui/joy/Input";
import Logo from "../../components/Logo/logo";
import { Button } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import toast from "react-hot-toast";
import { useChangePassword } from "../../api/users/use-change-password";
import axios from "axios";
import { Link } from "react-router-dom";

function ForgotPassword(props) {
  return (
    <div className="main-signin">
      <div className="floaty-middle">
        <div>
          <Logo />
          <div style={{ fontSize: "3rem", fontWeight: "bold" }}>
            Forgot password?
          </div>
          <div style={{ fontSize: "2rem", fontWeight: "bold" }}>
            Receive password reset link
          </div>
        </div>
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            try {
              const response = await axios.post(
                `https://api.savoie.ai/auth/users/reset_password/`,
                formJson
              );
              toast.success("Email sent");
            } catch (error) {
              toast.error("Could not send request");
            }
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <div>E-mail </div>
            <Input
              style={{ width: "100%" }}
              placeholder=""
              variant="soft"
              type="email"
              name="email"
            />

            <br></br>
            <Button
              style={{
                padding: "10px 25px",
                marginLeft: "auto",
                fontSize: "1.2rem",
              }}
              type="submit"
              color="neutral"
              variant="soft"
            >
              Send link
            </Button>
          </Stack>
        </form>

        <Link
          style={{ textDecoration: "none" }}
          to="/sign-in"
          className="formatted-link"
        >
          Sign in?
        </Link>
      </div>
    </div>
  );
}

export default ForgotPassword;
