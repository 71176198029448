import React, { useState,useMemo } from 'react';
import './deposit-modal.css';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Add from '@mui/icons-material/Add';
import TermsAndConditions from '../terms-and-conditions/terms-and-conditions';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { useListTransactions } from '../../api/transactions/use-list-transactions.js';
import { useAddTransactions } from '../../api/transactions/use-add-transaction.js';

function DepositModal(props){
    const [userID, setUserID] = useState(props.ID);
    const [openDeposit1, setOpenDeposit1] = React.useState(false);
    const [depositValue, setDepositValue] = React.useState('');
    const [currencyValue, setCurrencyValue] = React.useState('BTC');
    const handleDepositChange = (event) => {setDepositValue(event.target.value)};
    const handleCurrencyChange = (event, newValue) => {setCurrencyValue(newValue);};
    
    const validDepositValue =()=>{
        return (depositValue>0);
    }

    
    const { mutate: addTransaction, isPending } = useAddTransactions({
        id: userID,
        callBackOnSuccess:()=>{
        }
      });
    
    const { data: transactions } = useListTransactions();
    return(
        <div className="button-left" style={{marginLeft:"auto",height:"4rem"}} >
            
            <Button onClick={() => setOpenDeposit1(true)}variant="soft" size="lg" startDecorator={<Add />}>
                Add deposit
            </Button>
        <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openDeposit1}
        onClose={() => setOpenDeposit1(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
            variant="outlined"
            sx={{
                maxWidth: 500,
                minWidth:{xs:100,md:400},
                borderRadius: 'md',
                p: 3,
                boxShadow: 'lg',
            }}
            >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
                component="h2"
                level="h2"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
            >
            Deposit
            </Typography>
            <Divider orientation="horizontal" />
            <form
            
            onSubmit={(event) => {
                    event.preventDefault();
                    const formData = new FormData(event.target)
                    const requestData = {};
                    formData.append("trans_reason","INVESTMENT")
                    formData.append("trans_type","CREDIT")
                    // formData.forEach((value, key) => {
                    // if (key.startsWith('profile.')) {
                    //     const profileKey = key.split('.')[1];
                    //     requestData.profile = { ...requestData.profile, [profileKey]: value };
                    // } else {
                    //     requestData[key] = value;
                    // }
                    // })
                    setUserID(transactions[0].owner)
                    addTransaction(formData)
                    }}
                    >
                <div style={{fontSize:"20px",padding:"10px 0px"}}>
                    <div>Deposit amount type</div>
                    <Select defaultValue="BTC"
                    onChange={handleCurrencyChange} >
                    <Option value="BTC">BTC</Option>
                    <Option value="USD">USD</Option>
                    </Select>                
                    <div>Amount to deposit</div>
                
                <Stack spacing={1.5}>
                <Input
                    style={{width:"auto"}}
                    value={depositValue} 
                    onChange={handleDepositChange}
                    placeholder="Enter amount"
                    type="number"
                    endDecorator={
                    <React.Fragment>
                        <Divider orientation="vertical" />
                        <div style={{paddingLeft:"10px"}}>
                        {currencyValue}
                        </div>
                    </React.Fragment>
                    }
                    sx={{ width: 300 }}
                />
                <div>Deposit mode</div>
                    <Select name="trans_mode" defaultValue="BANK">
                    <Option value="BANK">Bank</Option>
                    <Option value="CRYPTO">Crypto</Option>
                    </Select>    
                <div>Note</div>
                <Input placeholder="Optional Note" name="note" />
                <Button
                disabled={!validDepositValue()}
                size="lg"
                type="submit" >Confirm</Button>
                </Stack>
                </div>
                </form> 
        </Sheet>
      </Modal>





      
      </div>

    );
};

export default DepositModal;