import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useListTransactionMonthly = () => {
  return useReadData({
    queryKey: [["transactions_monthly"]],
    endpoint: API.transactions_monthly,
  });
};

export { useListTransactionMonthly };
