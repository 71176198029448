import axios from "axios";
import { getAccessToken } from "./auth-storage";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = `https://api.savoie.ai`;

// Adding Authorization header for all requests
axiosClient.interceptors.request.use(
  (config) => {
    // Get the access token if available
    const token = getAccessToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Determine Content-Type based on the data
    if (config.data) {
      if (config.data instanceof FormData) {
      } else if (typeof config.data === "object") {
      } else if (typeof config.data === "string") {
        // Assuming text/plain for string data, adjust as needed
        config.headers["Content-Type"] = "text/plain";
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosClient;
