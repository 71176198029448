import "./search-bar.css";
import * as React from "react";
import { Input as BaseInput } from "@mui/base/Input";
import { styled } from "@mui/system";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Avatar from "@mui/material/Avatar";
import classnames from "classnames";
import { Link } from "react-router-dom";

// import Autocomplete from '@mui/joy/Autocomplete';
// import LiveTv from '@mui/icons-material/LiveTv';

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function SearchBar(props) {
  const options = {
    Home: "/",
    "Notification center": "/notificationcenter",
    Profile: "/profile",
    "Payment methods": "/paymentMethod",
    Deposit: "/deposit",
    Payout: "/payout",
    Statement: "/statement",
    "Ant Pool": "/AntPool",
  };

  // Convert the keys to an array for the autocomplete component
  const optionKeys = Object.keys(options);

  const handleOptionSelect = (event, value) => {
    if (value && options[value]) {
      // Redirect the user to the selected page link
      window.location.href = options[value];
    }
  };
  return (
    <div className={classnames("search-bar-main", props.styleName)}>
      <div className="hide-when-wide"></div>
      <div className="search-bar-input">
        <Autocomplete
          freeSolo
          options={optionKeys}
          onChange={handleOptionSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search..."
              variant="outlined"
              sx={{
                minWidth: "200px",
                width: "100%",
                height: "48px",
                padding: "0px 8px",
                marginLeft: "10px",
                border: 0,
                boxSizing: "border-box",
                borderRadius: "24px",
                opacity: 0.5,
                backgroundColor: "#c2c2c2",
                fontWeight: 500,
                lineHeight: "24px",
                outline: "none",
                "& .MuiOutlinedInput-root": {
                  padding: "0 8px", // Adjust padding inside the input
                  "& fieldset": {
                    border: "none", // Removes border around the input field
                  },
                },
              }}
            />
          )}
        />
      </div>
      <div className="wlc-notif-avatar">
        <div className="wlc">
          Welcome Back
          {props.userData && props.userData.length > 1
            ? "," + props.userData[0].first_name
            : ""}
        </div>

        <div className="notif">
          <Link to="/notificationCenter">
            <Badge
              //  badgeContent={4}
              color="secondary"
            >
              <NotificationsIcon
                color="action"
                style={{ fill: "white", width: "35", height: "35" }}
              />
            </Badge>
          </Link>
        </div>
        <Link to="/profile" style={{ textDecoration: "none" }}>
          <Avatar className="fake-button" sx={{ bgcolor: "lightblue" }}>
            {props.userData &&
            props.userData.length > 1 &&
            props.userData[0].first_name.length > 0
              ? props.userData[0].first_name[0]
              : ""}
            {props.userData &&
            props.userData.length > 1 &&
            props.userData[0].last_name.length > 0
              ? props.userData[0].last_name[0]
              : ""}
          </Avatar>
        </Link>
      </div>
    </div>
  );
}

export default SearchBar;
