import * as React from "react";
import "./logo.css";

import { Link } from "react-router-dom";
function logo() {
  return (
    <Link to="/" style={{ textDecoration: "none" }}>
      {/* <img src="logo.svg" alt="Savoie"></img> */}
      <div className="logo">SAVOIE</div>
    </Link>
  );
}

export default logo;
