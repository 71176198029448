
import * as React from 'react';
import './notification-center.css';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import SearchBar from '../../components/search-bar/search-bar.js';
import  {
  accordionSummaryClasses,
} from '@mui/joy/AccordionSummary';
import CampaignIcon from '@mui/icons-material/Campaign';
import RedeemIcon from '@mui/icons-material/Redeem';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import WalletIcon from '@mui/icons-material/Wallet';
import { useListNewsletter } from '../../api/transactions/use-list-newsletter.js';
import Typography from '@mui/joy/Typography';
import Divider from "@mui/joy/Divider";


const NewsletterCard = ({ newsletter }) => {
    return (
        <>
        <Typography level="title-md">{newsletter.subject}</Typography>
        <Typography color="primary" level="body-sm">{new Date(newsletter.created_at).toLocaleDateString()}</Typography>
         <div style={{fontFamily:"Helvetica"}}dangerouslySetInnerHTML={{ __html: newsletter.content }} />
      <Divider />

        </>
        
    );
};
function NotificationCenter(props){
    const [index, setIndex] = React.useState(-1);
    const { data: newsletters } = useListNewsletter();
    console.log(newsletters)
    const announcements = [];
    const campaigns = [];
    const systemUpdates = [];
    const wallets = [];
    const others = [];
    newsletters?.forEach(newsletter => {
        switch (newsletter.notification_type) {
            case "ANNOUNCEMENTS":
                announcements.push(newsletter);
                break;
            case "CAMPAIGN":
                campaigns.push(newsletter);
                break;
            case "SYSTEM_UPDATES":
                systemUpdates.push(newsletter);
                break;
            case "WALLETS":
                wallets.push(newsletter);
                break;
            default:
                others.push(newsletter); // To handle unexpected types
        }
    });

    return(
        <div className="main-content">
            <SearchBar userData={props.userData && props.userData.length > 0 ? props.userData:[]}/>
            <div className="main-overview">
                <h1 className="title">Notification center</h1>   
                <div className="cards one-column one-row">
                <div className="card">
                <AccordionGroup sx={{
                [`& .${accordionSummaryClasses.indicator}`]: {
                transition: '0.2s',
                },
                [`& [aria-expanded="true"] .${accordionSummaryClasses.indicator}`]: {
                transform: 'rotate(0deg)',
                },
                width: "auto" }} size="lg">
                    <Accordion
                        expanded={index === 0}
                        onChange={(event, expanded) => {
                        setIndex(expanded ? 0 : null);
                        }}
                        
                    >
                        <AccordionSummary 
                        // indicator={<Avatar variant="square"
                        //     sx={{ width: 20, height: 20,backgroundColor:"#236382" ,fontSize:"12px"}}
                        //     >
                        //     6
                        // </Avatar>}
                         >
                        <Stack direction="row" 
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            >
                             <CampaignIcon/>
                        <div> Announcements     </div>
                            
                        </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                        <List marker="disc">
                        {announcements?.length>0?(announcements.map((row) => (
                                <NewsletterCard key={row.id} newsletter={row} />
                        ))):<p>No announcements</p>}
                        
                        </List>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={index === 1}
                        onChange={(event, expanded) => {
                        setIndex(expanded ? 1 : null);
                        }}
                    >
                        <AccordionSummary
                        // indicator={<Avatar variant="square"
                        //     sx={{ width: 20, height: 20,backgroundColor:"#236382" ,fontSize:"12px"}}
                        //     >
                        //     0
                        // </Avatar>}
                        >
                            
                            
                        <Stack direction="row" 
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}>
                        <RedeemIcon/>
                        <div>Campaign     </div>
                            
                        </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                        {campaigns?.length>0?(campaigns.map((row) => (
                                <NewsletterCard key={row.id} newsletter={row} />
                        ))):<p>No campaign announcements</p>}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={index === 2}
                        onChange={(event, expanded) => {
                        setIndex(expanded ? 2 : null);
                        }}
                    >
                        <AccordionSummary 
                        // indicator={<Avatar variant="square"
                        //     sx={{ width: 20, height: 20,backgroundColor:"#236382" ,fontSize:"12px"}}
                        //     >
                        //     0
                        // </Avatar>}
                        >
                        <Stack direction="row" 
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}>
                        <SystemUpdateIcon/>
                        <div>System updates     </div>
                            
                        </Stack></AccordionSummary>
                        <AccordionDetails>
                        {systemUpdates?.length>0?(systemUpdates.map((row) => (
                                <NewsletterCard key={row.id} newsletter={row} />
                        ))):<p>No system update announcements</p>}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={index === 3}
                        onChange={(event, expanded) => {
                        setIndex(expanded ? 3 : null);
                        }}
                    >
                        <AccordionSummary 
                        // indicator={<Avatar variant="square"
                        //     sx={{ width: 20, height: 20,backgroundColor:"#236382" ,fontSize:"12px"}}
                        //     >
                        //     9+
                        // </Avatar>}
                        >
                        <Stack direction="row" 
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}>
                        <WalletIcon fontSize="medium"/> 
                        <div>Wallets     </div>
                            
                        </Stack></AccordionSummary>
                        <AccordionDetails>
                        
                        {wallets?.length>0?(wallets.map((row) => (
                                <NewsletterCard key={row.id} newsletter={row} />
                        ))):<p>No wallet announcements</p>}
                        </AccordionDetails>
                    </Accordion>
                </AccordionGroup>
            
            </div>
            </div>
            </div>
        </div>)

}
export default NotificationCenter;