import * as React from 'react';
import './terms-and-conditions.css';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import ModalDialog from '@mui/joy/ModalDialog';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import classnames from 'classnames';
import Button from '@mui/joy/Button';

function TermsAndConditions(props){

    const [openTerms, setOpenTerms] = React.useState(false);
    return (

        <div>
            <a className={classnames(props.styleName,"black-ref")} 
                onClick={() => setOpenTerms(true)}
                >
                    <Typography
                level="title-lg" variant="plain"
                >
                By clicking {props.text !=null?props.text:"yes"}, you agree to the Terms and Conditions
                </Typography>
                </a>
                <Modal
                    aria-labelledby="modal-title"
                    aria-describedby="modal-desc"
                    open={openTerms}
                    onClose={() => setOpenTerms(false)}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                     >
                    <ModalDialog layout="fullscreen">
                    <ModalClose />
                    

                    <Typography textColor="black" color="neutral" level="h1"> Terms and Conditions</Typography>
                    <Divider/>

                    <Typography
                    color="neutral" textColor="black" level="title-lg"> Lorem Ipsum</Typography>
                    <Typography textColor="black" color="neutral" level="body-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sed mi vel diam pulvinar ullamcorper vestibulum sit amet quam. Maecenas nibh nibh, vehicula et ligula gravida, accumsan elementum risus. Nulla facilisi. Donec justo metus, accumsan dictum ante eget, euismod suscipit mi. Donec et massa nec ante interdum accumsan. Nullam ut sagittis neque. Nullam bibendum mi at tellus mattis, laoreet dignissim risus bibendum. Sed finibus cursus accumsan. Aliquam consequat purus sed venenatis ultricies.</Typography>
                    <List marker="upper-roman" className="black-text-children">
                        <ListItem>The Shawshank Redemption</ListItem>
                        <ListItem nested>
                        <ListItem>Star Wars</ListItem>
                        <List marker="disk">
                            <ListItem>Episode I – The Phantom Menace</ListItem>
                            <ListItem>Episode II – Attack of the Clones</ListItem>
                            <ListItem>Episode III – Revenge of the Sith</ListItem>
                        </List>
                        </ListItem>
                        <ListItem>The Lord of the Rings: The Two Towers</ListItem>
                    </List>
                    <Button
                        color="danger"
                        onClick={() => setOpenTerms(false)}
                        
                        >
                        Close 
                    </Button>
                    </ModalDialog>
                </Modal>

        </div>
    );
}

export default TermsAndConditions;