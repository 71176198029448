import { API } from "../api-end-point";
import { usePostData } from "../api-service/usePostData";
import { setAccessToken } from "../auth-storage";
import { setRefreshToken } from "../auth-storage";

const useLoginUser = ({ callBackOnSuccess }) => {
  return usePostData({
    hideSuccessToast:true,
    queryKeysToInvalidate: [["auth"]],
    endpoint: API.creat_auth,
    callBackOnSuccess: (data) => {
      callBackOnSuccess && callBackOnSuccess();
      setAccessToken(data.access); // This is now safe to call
      setRefreshToken(data.refresh);
    },
  });
};

export { useLoginUser };
