import { API } from "../api-end-point";
import { usePatchData } from "../api-service/usePatchData";

export const useEditMemberList = ({ id, callBackOnSuccess }) =>
    usePatchData({
    endpoint:   API.updateUser(id),
    queryKeysToInvalidate: [["users"]],
    callBackOnSuccess,
    hideSuccessToast:true,
  });
