import * as React from 'react';
import './table.css'
import { useListDeposits } from "../../api/transactions/use-list-deposits.js";
import DateTimeDisplay from '../../components/date-time-display.js';

export default function EnhancedTable() {
  const { data: deposits } = useListDeposits();
  return (
    <table>
      <thead >
        <tr>
          <th>Date</th>
          <th>Amount USD</th>
          <th>Method</th>
          <th>Reason</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
            {deposits?.slice(0, 3).map((row) => (
              <tr  key={row.pk}>
                <td><DateTimeDisplay length={"short"} apiDateTime={row.trans_date}/></td>
                <td>{row.trans_amt_usd}$</td>
                <td>{row.trans_mode}</td>
                <td>{row.trans_reason}</td>
                <td>{row.trans_status}</td>
              </tr>
            ))}
          </tbody>
        </table>
  );
}
