import "./home-overview.css";
import SearchBar from "../../components/search-bar/search-bar.js";
import Overview from "../../components/overview/overview.js";
function HomeOverview(props) {
  return (
    <div className="main-content">
      <SearchBar
        userData={
          props.userData && props.userData.length > 0 ? props.userData : []
        }
        styleName="desktop-search"
      />
      <Overview />
    </div>
  );
}

export default HomeOverview;
