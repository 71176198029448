import axios from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { setAccessToken } from "../auth-storage";

function parseJwt(token) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
}

function isTokenExpired(token) {
  const decodedToken = parseJwt(token);
  if (!decodedToken) {
    return true;
  }
  const currentTime = Date.now() / 1000;
  return decodedToken.exp < currentTime;
}

export default function useAuth() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const checkAuthTokens = async () => {
        const accessToken = localStorage.getItem("AUTH_TOKEN");
        const refreshToken = localStorage.getItem("REFRESH_TOKEN");

        if (!accessToken || isTokenExpired(accessToken)) {
          if (!refreshToken || isTokenExpired(refreshToken)) {
            // Both tokens are expired, redirect to sign-in page
            localStorage.removeItem("AUTH_TOKEN");
            localStorage.removeItem("REFRESH_TOKEN");
            const currentPath = location.pathname;
            if (
              currentPath !== "/sign-in" &&
              currentPath !== "/forgot-password" &&
              currentPath !== "/sign-up" 
              && currentPath.includes("password_reset") == false
            ) {
              console.log(currentPath);
              navigate("/sign-in");
            }
          } else {
            try {
              const response = await axios.post(
                "https://api.savoie.ai/auth/jwt/refresh/",
                {
                  refresh: refreshToken.replace(/['"]+/g, ""),
                }
              );
              setAccessToken(response.data.access);
            } catch (error) {
              if (error.response && error.response.status === 401) {
                // Refresh token is invalid/expired, redirect to sign-in
                localStorage.removeItem("AUTH_TOKEN");
                localStorage.removeItem("REFRESH_TOKEN");
                toast.error("Your login credentials expired");
                navigate("/sign-in");
              } else {
                console.error("Failed to refresh token:", error);
              }
            }
          }
        }
      };
      checkAuthTokens();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [navigate]);
}
