import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useListTotalReturn = () => {
  return useReadData({
    queryKey: [["totalReturns"]],
    endpoint: API.totalReturn,
  });
};

export { useListTotalReturn };
