import { API } from "../api-end-point";
import { usePostData } from "../api-service/usePostData";
// just to request the link
export const useChangePassword = ({ callBackOnSuccess }) =>
  usePostData({
    hideSuccessToast:true,
    endpoint: API.askChangePassword,
    queryKeysToInvalidate: [["password"]],
    callBackOnSuccess,
  });
