export const API = {
  // ----------------- User --------------------
  login: "/api/users/login",
  creat_auth: "/auth/jwt/create/",
  membersList: "/api/users/",
  pendingRegistration:
    "/api/transactions/?trans_reason=REGISTRATION&trans_status=PENDING",
  pendingKYCRegistration: "/api/users/?kyc_approved=false",
  updateUser: (id) => `/api/users/${id}/update/`,
  askChangePassword: "/auth/users/reset_password/",
  setNewPassword: "/auth/users/reset_password_confirm/",
  addUser: "/api/users/create/",

  // ----------------- Transactions --------------------
  transactions: "/api/transactions/",
  pendingCommission: "/api/transactions/pendingcommission",
  transactionsInterest: "/api/transactions/?trans_reason=COMMISSION",
  transactionsPayout: "/api/transactions/?trans_reason=PAYOUT",
  transactionsDeposit:
    "/api/transactions/?trans_reason__in=INVESTMENT,REGISTRATION",
  updateTransactions: (id) => `/api/transactions/${id}/update/`,
  monthlyReturn: "/api/transactions/monthly/?trans_status=APPROVED",
  dailyReturn: "/api/transactions/daily/?trans_status=APPROVED",
  totalReturn: "/api/transactions/total/",
  transactions_monthly: "/api/transactions/monthly/",
  custom_transactions_monthly: "/api/transactions/monthly/?months=6",
  newsLetter: "/api/newsletters",
  // ----------------- Token --------------------
  refreshAccess: "/auth/jwt/refresh/",
};
