import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useMembersList = () => {
  return useReadData({
    queryKey: [["membersList"]],
    endpoint: API.membersList,
  });
};

export { useMembersList };
