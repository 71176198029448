import "./password-reset.css";
import Input from "@mui/joy/Input";
import Logo from "../../components/Logo/logo";
import { Button } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useResetPassword } from "../../api/users/use-reset-password";

function PasswordReset() {
  const navigate = useNavigate(); // Get the navigate function

  const { uid, token } = useParams();
  const { mutate: updatePassword, isPending } = useResetPassword({
    callBackOnSuccess: () => {
      toast.success("request sent");

      navigate("/sign-in");
    },
  });
  return (
    <div className="main-signin">
      <div className="floaty-middle">
        <div>
          <Logo />
          <div style={{ fontSize: "3rem", fontWeight: "bold" }}>
            Forgot password?
          </div>
        </div>
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            formData.append("token", token);
            formData.append("uid", uid);
            updatePassword(formData);
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <div>Enter new password</div>
            <Input
              style={{ width: "100%" }}
              placeholder=""
              variant="soft"
              name="new_password"
              type="password"
            />
            <div>Re-enter new password</div>
            <Input
              style={{ width: "100%" }}
              placeholder=""
              variant="soft"
              name="re_new_password"
              type="password"
            />

            <br></br>
            <Button
              style={{
                padding: "10px 25px",
                marginLeft: "auto",
                fontSize: "1.2rem",
              }}
              type="submit"
              color="neutral"
              variant="soft"
            >
              Update password
            </Button>
          </Stack>
        </form>
        <Link
          style={{ textDecoration: "none" }}
          to="/sign-in"
          className="formatted-link"
        >
          Sign in?
        </Link>
      </div>
    </div>
  );
}

export default PasswordReset;
