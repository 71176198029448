import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useListDeposits = () => {
  return useReadData({
    queryKey: [["Deposits"]],
    endpoint: API.transactionsDeposit,
  });
};

export { useListDeposits };
