const AUTH_TOKEN_KEY = "AUTH_TOKEN";
const REFRESH_TOKEN_KEY="REFRESH_TOKEN";
export function setAccessToken(token) {
  localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(token));
}
export function setRefreshToken(token) {
  localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify(token));
}


export function getAccessToken() {
  try {
    return localStorage.getItem(AUTH_TOKEN_KEY)
      ? JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY))
      : "";
  } catch (error) {
    return "";
  }
}

export function clearAccessToken() {
  localStorage.removeItem(AUTH_TOKEN_KEY);

  window.location.href = "/sign-in";
}

