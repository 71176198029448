import "./overview.css";
import { useState, useEffect } from "react";
import UnstyledSelectIntroduction from "../select-button/select-button.js";
import ReinjectModal from "../reinject-modal/reinject-modal.js";
import PayoutModal from "../payout-modal/payout-modal.js";
import { LineChart } from "@mui/x-charts/LineChart";
import EnhancedTable from "../table/table.js";
import Button from "@mui/joy/Button";
import { useListTotalReturn } from "../../api/transactions/use-list-total-return.js";
import { useListTransactionMonthly } from "../../api/transactions/use-list-transactions-monthly.js";
import { useListPensingCommission } from "../../api/transactions/use-list-pending-commissions.js";
import { useMembersList } from "../../api/members-list/use-list-member-list.js";
import { useNavigate } from "react-router-dom";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { useListCustomTransactionMonthly } from "../../api/transactions/use-list-custom-transactions-monthly.js";

function formatLargeNumber(num) {
  return Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(num);
}

function Overview() {
  const { data: profileInfo } = useMembersList();
  const { data: totalReturn } = useListTotalReturn();
  const { data: pendingCommission } = useListPensingCommission();
  const { data: allMonthlyTransactions } = useListTransactionMonthly();
  const { data: customMonthlyTransactionsSixMonths } =
    useListCustomTransactionMonthly("6");
  const [selectedValue, setSelectedValue] = useState("max");

  const [monthlyTransactions, setMonthlyTransactions] = useState(null);

  const handleChange = (value) => {
    setSelectedValue(value);
    if (value === "6") {
      setMonthlyTransactions(customMonthlyTransactionsSixMonths);
      console.log(customMonthlyTransactionsSixMonths);
    } else {
      setMonthlyTransactions(allMonthlyTransactions);
    }
  };

  useEffect(() => {
    if (allMonthlyTransactions) {
      setMonthlyTransactions(allMonthlyTransactions);
    }
  }, [allMonthlyTransactions]);
  const uData = monthlyTransactions?.map((item) => item.sum_commission_usd);
  const xLabels = monthlyTransactions?.map((item) => item.date);

  const navigate = useNavigate();
  return (
    <div className="main-overview">
      <h1 className="title">Overview</h1>
      <div className="cards">
        <div className="card">
          <div className="subtitle">Total deposits</div>
          <div className="big-text-blue">
            {totalReturn?.sum_registration_investment_btc_approved?.toFixed(5)}{" "}
            BTC
          </div>
          <div className="medium-text-grey">
            ~{" "}
            {formatLargeNumber(
              totalReturn?.sum_registration_investment_usd_approved
            )}{" "}
            USD
          </div>
          {/* <div  className="smaller-text-blue">
                    Recent
                </div> */}
          <EnhancedTable />
          <div className="button-left align-bottom">
            <Button
              color="primary"
              variant="soft"
              size="lg"
              onClick={() => {
                navigate("/statement");
              }}
            >
              History
            </Button>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className="card">
            <div className="subtitle">ROI</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <div className="small-text-grey"> Last Month</div>
                <div className="small-text-blue">
                  {" "}
                  {totalReturn?.sum_commission_btc_approved?.toFixed(2)}%
                </div>
              </div>
              <div>
                <div className="small-text-grey"> Since beginning</div>
                <div className="small-text-blue">
                  {totalReturn?.sum_commission_btc_approved?.toFixed(2)}%
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="subtitle">Earnings</div>
            <div className="big-text-blue">
              {pendingCommission && pendingCommission.length > 0
                ? pendingCommission[0].trans_amt_btc
                : "NO"}{" "}
              BTC
            </div>
            <div className="medium-text-grey">
              ~{" "}
              {pendingCommission && pendingCommission.length > 0
                ? formatLargeNumber(pendingCommission[0].trans_amt_usd)
                : "NO"}{" "}
              USD
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              justifyItems: "stretch",
              gap: "10px",
            }}
          >
            <ReinjectModal
              ID={
                profileInfo && profileInfo.length > 0 ? profileInfo[0].id : -1
              }
              value_btc={
                pendingCommission && pendingCommission.length > 0
                  ? pendingCommission[0].trans_amt_btc
                  : 0
              }
              value_usd={
                pendingCommission && pendingCommission.length > 0
                  ? pendingCommission[0].trans_amt_usd
                  : 0
              }
            />
            <PayoutModal
              ID={
                profileInfo && profileInfo.length > 0 ? profileInfo[0].id : -1
              }
              value_btc={
                pendingCommission && pendingCommission.length > 0
                  ? pendingCommission[0].trans_amt_btc
                  : 0
              }
              value_usd={
                pendingCommission && pendingCommission.length > 0
                  ? pendingCommission[0].trans_amt_usd
                  : 0
              }
            />
          </div>
        </div>

        <div className="card chart-div">
          <div
            className="subtitle-and-dropdown"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="subtitle">Monthly interest (USD)</div>

            <div style={{ marginLeft: "auto" }}>
              <Select
                defaultValue="max"
                onChange={(e, newValue) => handleChange(newValue)}
              >
                <Option value="6">Six months</Option>
                <Option value="max">Max</Option>
              </Select>
            </div>
          </div>
          {xLabels && xLabels.length > 2 ? (
            <LineChart
              xAxis={[{ scaleType: "point", data: xLabels }]}
              series={[{ data: uData, id: "uvId", color: "#236382" }]}
              grid={{ vertical: true, horizontal: true }}
            />
          ) : (
            <div className="medium-text-grey">Not enough data</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Overview;
