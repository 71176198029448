import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useListPensingCommission = () => {
  return useReadData({
    queryKey: [["comission"]],
    endpoint: API.pendingCommission,
  });
};

export { useListPensingCommission };
